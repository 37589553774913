import React, { useEffect, useState } from 'react'
import s from './startPage.module.css'
import pic1 from '../img/start1.png'
import pic2 from '../img/start2.png'
import SecondPage from './SecondPage'
import { useAppSelector } from '../app/Hooks'
import { selectorStart } from '../app/selectors'
import { setStart } from '../app/slice'
import { useDispatch } from 'react-redux'


type Props = {}

const StartPage = (props: Props) => {

    const [styleS, setStyle] = useState<string>(s.pic2)
    const start = useAppSelector(selectorStart)
    const dispatch = useDispatch();


    useEffect(() => {
        if (!start) {
            setTimeout(() => {
                setStyle(s.pic3)
            }, 4000);

            setTimeout(() => {
                dispatch(setStart(true))
            }, 7000);
        }

    }, [dispatch, start])



    return (
        <div className={s.startPage}>

            <div className={s.conteiner}>
                {start && <SecondPage />
                }
                <div style={start ? { display: "none" } : { display: "block" }} className={styleS}>

                    <img src={pic2} alt="" />
                </div>

                <div style={start ? { display: "none" } : { display: "block" }} className={s.pic1}  >
                    <img src={pic1} alt="" />
                </div>

            </div>


        </div>

    )
}
export default StartPage