import React, { useState } from 'react'
import s from './navBarM.module.css'
import pic from '../../img/spichki_WWord.png'
import { PiHamburgerThin } from "react-icons/pi";
import { IBar } from '../../app/interfaces';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

type Props = {
  bar: IBar
}

const NavBarM = ({ bar }: Props) => {

  const navigate = useNavigate()
  const [show, setShow] = useState(false)

  const { pathBar } = bar
  const BurgerM = () => {
    return (
      <div className={s.burgerM}>
        <div className={s.item}>
          <div
            onClick={() => navigate(`/${pathBar}`)}
            className={s.menuItem}> На главную
          </div>
          <div
            onClick={() => navigate(`/${pathBar}/menu`)}
            className={s.menuItem}>Меню
          </div>

          <div onClick={() => { window.open('https://t.me/spi4ki_bot') }}
            className={s.menuItem}>Бронирование</div>

          <div
            onClick={() => { navigate(`/${pathBar}/stock`) }} className={s.menuItem}>Акции</div>
          <div
            onClick={() => { navigate(`/${pathBar}/contacts`) }} className={s.menuItem}>Контакты</div>

        </div>
      </div>
    )
  }

  return (
    <>
      <div className={s.navBarM} >

        <img onClick={() => {
          navigate('/')
        }} src={pic} alt="" />

        <div>
          <PiHamburgerThin style={{ marginRight: '20px' }} color='#fff' size={40}
            onClick={() => setShow(!show)} />
        </div>

      </div>
      <AnimatePresence>

        {show && <motion.div
          key='burger'
          initial={{ marginLeft: '-100%' }}
          animate={{ marginLeft: '0%' }}
          exit={{ marginLeft: '100%' }}
          transition={{ duration: 1 }}
        >
          <BurgerM />
        </motion.div>}
      </AnimatePresence>




    </>

  )
}
export default NavBarM
