import React, { useEffect, useState } from 'react'
import s from './menu.module.css'
import NavBar2 from './NavBar2'
import pic from "../../img/dish.png"
import { IBar, IFood, IMenu } from '../../app/interfaces'
import menuKoms from '../../menu_drink_koms.json'
import { FaMinus, FaPlus } from "react-icons/fa6";
import NavBarM from '../mobile/NavBarM'
import { motion } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '../../app/Hooks'
import { useGetMenuQuery } from '../../app/slice_API'
import { showSeason } from '../../app/slice'

type Props = {
  bar: IBar
  anim4: string
}

const Menu = ({ bar, anim4 }: Props) => {

  const [plusDrink, setPlusDrink] = useState<boolean[]>([true])
  const [plusFood, setPlusFood] = useState<boolean[]>([true])
  const [plusSeason, setPlusSeason] = useState<boolean[]>([true])
  const [menu, setMenu] = useState<IMenu>(menuKoms)
  const seasonShow = useAppSelector(state => state.spichki.season)
  const { tel, pathBar, name } = bar
  const currMenu = useGetMenuQuery(pathBar)
  const dispatch = useAppDispatch()
  const [menuSeason, setMenuSeason] = useState<IFood[]>([{
    name: '',
    list:
      [{
        name: '',
        gramm: '',
        discr: '',
        cost: ''
      }]
  }])

  useEffect(() => {
    if (currMenu.data?.menu) {
      setMenu(currMenu.data.menu)
      dispatch(showSeason(currMenu.data.season))
     
    }
    if (currMenu.data?.seasonMenu) setMenuSeason(currMenu.data.seasonMenu)
   
   
  }, [pathBar, currMenu.data, dispatch])


  useEffect(() => {
    window.scrollTo(0, 0)
    setPlusDrink(menu.drinks.map(el => true))
    setPlusFood(menu.food.map(el => true))
    setPlusSeason(menuSeason.map(el => true))
  }, [menu, menuSeason])


  const onClick = (index: number, menuShow: string) => {
    if (menuShow === 'drink') {
      let a = plusDrink.map((el, i) => {
        if (i === index) {
          return !el
        }
        return true
      })

      setPlusDrink([...a])
      setPlusFood(menu.food.map(el => true))
      setPlusSeason(menuSeason.map(el => true))
    }
    if (menuShow === 'food') {
      let a = plusFood.map((el, i) => {
        if (i === index) {
          return !el
        }
        return true
      })

      setPlusFood([...a])
      setPlusDrink(menu.drinks.map(el => true))
      setPlusSeason(menuSeason.map(el => true))

    }
    if (menuShow === 'season') {
      let a = plusSeason.map((el, i) => {
        if (i === index) {
          return !el
        }
        return true
      })

      setPlusSeason([...a])
      setPlusDrink(menu.drinks.map(el => true))
      setPlusFood(menu.food.map(el => true))
    }
  }



  const itemDrinks = (index: number) => {
    return menu.drinks[index].list.map((el, i) => {
      return (
        <motion.div whileHover={{ backgroundColor: '#0e0e0e', color: '#fff' }} className={s.drinkItem} key={el + i}>
          <div style={{ textAlign: "start", maxWidth: '70%' }}>
            <p>{el}</p>
            <p style={{ fontSize: "18px", fontWeight: 300 }}>{menu.drinks[index].discr[i]}</p>
          </div>

          <p style={{ textAlign: "end" }}>{menu.drinks[index].cost[i]}</p>

        </motion.div>
      )
    })
  }
  const itemFood = (index: number) => {
    return menu.food[index].list.map((el, i) => {
      return (
        <motion.div whileHover={{ backgroundColor: '#0e0e0e', color: '#fff' }} className={s.drinkItem} key={el.name + i}>
          <div style={{ textAlign: "start" }}>
            <p>{el.name}</p>
            <p style={{ fontSize: "18px", fontWeight: 300 }}>{menu.food[index].list[i].discr}</p>
            <p style={{ fontSize: "18px", fontWeight: 300 }}>{menu.food[index].list[i].gramm}</p>
          </div>

          <p style={{ textAlign: "end" }}>{menu.food[index].list[i].cost}</p>

        </motion.div>
      )
    })
  }
  const itemSeason = (index: number) => {
    return menuSeason[index].list.map((el, i) => {
      return (
        <motion.div whileHover={{ backgroundColor: '#0e0e0e', color: '#fff' }} className={s.drinkItem} key={el.name}>
          <div style={{ textAlign: "start" }}>
            <p>{el.name}</p>
            <p style={{ fontSize: "18px", fontWeight: 300 }}>{menuSeason[index].list[i].discr}</p>
            <p style={{ fontSize: "18px", fontWeight: 300 }}>{menuSeason[index].list[i].gramm}</p>
          </div>

          <p style={{ textAlign: "end" }}>{menuSeason[index].list[i].cost}</p>

        </motion.div>
      )
    })
  }
  const drinks = menu.drinks.map((el, i) => {
    return <div key={el.nameD}>

      <div className={plusDrink[i] ? s.wrapper : s.wrapperPlus}>
        <div className={s.hidden}>
          <motion.div whileHover={{ backgroundColor: '#0e0e0e', color: '#fff' }} className={s.wrapperName}>
            <h3>{el.nameD}</h3> <h3>{el.ml}</h3>
          </motion.div>

          {(plusDrink[i] && el.list[0].length > 0) ? <FaPlus onClick={() => { onClick(i, 'drink') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} /> :
            <FaMinus onClick={() => { onClick(i, 'drink') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} />}
        </div>
        {(!plusDrink[i] && el.list[0].length > 0) && <div className={s.plusA}>{itemDrinks(i)}</div>}
      </div>

      <hr />
    </div>
  })
  const food = menu.food.map((el, i) => {
    return <div key={el.name}>
      <div className={plusDrink[i] ? s.wrapper : s.wrapperPlus}>
        <div className={s.hidden}>
          <motion.div whileHover={{ backgroundColor: '#0e0e0e', color: '#fff' }} className={s.wrapperName}>
            <h3>{el.name}</h3>
            {(el.list[0].name === "") && <h3>{el.list[0].cost}</h3>}
          </motion.div>

          {(plusFood[i] && el.list[0].name.length > 0) ? <FaPlus onClick={() => { onClick(i, 'food') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} /> :
            <FaMinus onClick={() => { onClick(i, 'food') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} />}
        </div>
        {(!plusFood[i] && el.list[0].name.length > 0) && <div className={s.plusA}>{itemFood(i)}</div>}
      </div>

      <hr />
    </div>
  })

  const season = menuSeason.map((el, i) => {
    return <div key={el.name}>
      <div className={plusDrink[i] ? s.wrapper : s.wrapperPlus}>
        <div className={s.hidden}>
          <motion.div whileHover={{ backgroundColor: '#0e0e0e', color: '#fff' }} className={s.wrapperName}>
            <h3>{el.name}</h3>
            {(el.list[0].name === "") && <h3>{el.list[0].cost}</h3>}
          </motion.div>

          {(plusSeason[i] && el.list[0].name.length > 0) ? <FaPlus onClick={() => { onClick(i, 'season') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} /> :
            <FaMinus onClick={() => { onClick(i, 'season') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} />}
        </div>
        {(!plusSeason[i] && el.list[0].name.length > 0) && <div className={s.plusA}>{itemSeason(i)}</div>}
      </div>

      <hr />
    </div>
  })
  return (
    <div className={s.menu}>

      {(anim4 === 'navig') && <NavBar2
        nameBar={name}
        pathBar={pathBar}
        desactive={'Меню'}
        tel={tel}
        goAnim={1} />}
      {(anim4 === 'mobile') && <NavBarM bar={bar} />}



      <div style={currMenu.isFetching ? { opacity: 0.1 } : undefined} className={s.container}>
        <h1>МЕНЮ</h1>
        <img className={s.picMenu} src={pic} alt="" />

        {seasonShow &&
          <>
            <h2 style={{ color: 'red' }} >Сезонное предложение</h2>
            <div className={s.drinks}>
              {season}
            </div>
          </>
        }
        <h2>Напитки</h2>

        <div className={s.drinks}>
          {drinks}
        </div>

        <h2>ЗАКУСКИ</h2>
        <div className={s.drinks}>
          {food}
        </div>

      </div>

    </div>
  )
}
export default Menu