import React, { useState } from 'react'
import s from './menuEdit.module.css'
import { IFood } from '../../app/interfaces'
import { FaCircleCheck, FaCirclePlus, FaCircleXmark, FaPen, FaTrash } from 'react-icons/fa6'
type Props = {
    menu: IFood[]
    index: number
    changeMenu: (newFood: IFood[]) => void
}

const Food = ({ menu, index , changeMenu}: Props) => {

    const [edit, setEdit] = useState<number | null>(null)
    const [inputFood, setInputFood] = useState({
        cost: '', discr: '', name: '', gramm: ''
    })

    const addFood = (i: number) => {
        let newList = [...menu[index].list]
        newList.splice(i + 1, 0, { name: '', gramm: '', discr: '', cost: '' })

        let a = [...menu]

        a.splice(index, 1,
            { list: newList, name: a[index].name })

        changeMenu(a)
    }

    const delFood = (i: number) => {
        let newList = [...menu[index].list]
        newList.splice(i, 1)

        let a = [...menu]

        a.splice(index, 1,
            { list: newList, name: a[index].name })

        changeMenu(a)
    }
    const changeFood = (i: number) => {
        let newList = [...menu[index].list]
        newList.splice(i, 1, inputFood)
        let a = [...menu]

        a.splice(index, 1,
            { list: newList, name: a[index].name })

        changeMenu(a)
    }
    const editDrinks = (i: number) => {
        return (
            <>
                <div className={s.wrapperInput} style={{ textAlign: "start", width: '40%' }}>
                    <div>
                        <input type="text" value={inputFood.name}
                        onChange={(e) => {
                            setInputFood({
                                cost: inputFood.cost, discr: inputFood.discr,
                                name: e.currentTarget.value, gramm: inputFood.gramm
                            })
                        }}

                    />
                    <textarea value={inputFood.discr}
                        onChange={(e) => {
                            setInputFood({
                                cost: inputFood.cost, discr: e.currentTarget.value,
                                name: inputFood.name, gramm: inputFood.gramm
                            })
                        }}
                    />
                    </div>
                    
                    <input type="text" value={inputFood.gramm}
                        onChange={(e) => {
                            setInputFood({
                                cost: inputFood.cost, discr: inputFood.discr,
                                name: inputFood.name, gramm: e.currentTarget.value
                            })
                        }}

                    />


                    <input type="text" value={inputFood.cost}
                        onChange={(e) => {
                            setInputFood({
                                cost: e.currentTarget.value, discr: inputFood.discr,
                                name: inputFood.name, gramm: inputFood.gramm
                            })
                        }}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
                    <FaCircleCheck size={30} cursor={'pointer'} onClick={() => {
                        changeFood(i);
                        setEdit(null)
                    }} />
                    <FaCircleXmark size={30} cursor={'pointer'} onClick={() => { setEdit(null) }} />
                </div>

            </>
        )
    }
    const itemFood = menu[index].list.map((el, i) => {
        return (
            <div className={s.drinkItem} key={el.name + i}>
                {(edit !== i) ? <>
                    <div style={{ textAlign: "start", maxWidth: '70%' }}>
                        <p>{el.name}</p>
                        <p style={{ fontSize: "18px", fontWeight: 300 }}>
                            {el.discr}</p>
                        <p style={{ fontSize: "18px", fontWeight: 300 }}>
                            {el.gramm}</p>
                    </div>

                    <p style={{ textAlign: "end" }}>{el.cost}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '140px' }}>
                        <FaPen size={30}
                            cursor={'pointer'}
                            onClick={() => {
                                setEdit(i);
                                setInputFood({
                                    cost: el.cost,
                                    discr: el.discr,
                                    name: el.name,
                                    gramm: el.gramm
                                })
                            }} />
                        <FaTrash size={30}
                            cursor={'pointer'}
                            onClick={() => {
                                delFood(i)
                            }} />
                        <FaCirclePlus size={30} cursor={'pointer'} onClick={() => {
                            addFood(i)
                        }} />
                    </div>
                </>
                    : editDrinks(i)}


            </div>
        )
    })


    return (
        <>
            {itemFood}
        </>
    )
}

export default Food