import React from 'react'
import s from './contactsM.module.css'
import { IBar } from '../../app/interfaces'
import { RiInstagramLine, RiTelegramLine } from 'react-icons/ri'
import NavBarM from './NavBarM'


type Props = {
  bar: IBar
  anim4: string

}

const ContactsM = ({ bar, anim4 }: Props) => {

  return (
    <>
      {(anim4 = 'mobile') &&
        <NavBarM bar={bar} />
      }
        
      <div className={s.contacts} style={(anim4 !== 'navig') ? { zIndex: 30 } : { paddingTop: "80px" }}>

        <div className={s.map}>

          <iframe title='map' style={{ width: "100%", height: "90%" }} src={bar.map}  ></iframe>


        </div>

        <div className={s.text}>

          <h3 style={{ marginTop: "20px" }}>График работы</h3>
          {
            bar.schedule.map((el, i) => {
              return (
                <p key={el}>{el}</p>
              )
            })
          }
          <h3 style={{ marginTop: "40px" }}>Адрес</h3>

          <p>г.Минск</p>
          <p>{bar.adress}</p>
          <h3 style={{ marginTop: "40px" }}>Телефон</h3>
          <p style={{ marginBottom: '20px' }}>{bar.tel}</p>

          <RiInstagramLine size={30} onClick={() => { window.open(bar.inst) }} className={s.inst} />
          <RiTelegramLine size={30} onClick={() => { window.open('https://t.me/spi4ki_bot') }} className={s.inst} />
        </div>
      </div>
    </>

  )
}
export default ContactsM