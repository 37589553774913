import React, { useRef } from 'react'
import s from './barPages.module.css'
import pic from '../../img/dr2.jpg'
import pic2 from '../../img/athmos1.jpg'
import pic3 from '../../img/food1.jpg'
import snack2 from '../../img/snack2.jpg'
import athmos1 from '../../img/athm1.jpg'
import athmos2 from '../../img/athm2.jpg'
import { MotionValue, motion, useInView } from 'framer-motion'


type Props = {
    goAnim: string
    marginTop: MotionValue<number> | MotionValue<string>
}
const BarPages = ({ goAnim, marginTop }: Props) => {

    const ref = useRef(null)
    const isInView = useInView(ref, { amount: 0.2 })


    const variants = {
        start: {
            marginTop: '0%'
        },

        finish: {
            marginTop: '10%',

        }

    }
    const variants2 = {
        start: {
            marginTop: '10%',
            opacity: 1
        },

        finish: {
            marginTop: '40%',
            opacity: 1
        }
    }

    const variants3 = {
        hidden: {
            marginTop: '0%',
            opacity: 0
        },
        visible: (custom: number) => ({
            marginTop: '0%',
            opacity: 1,
            transition: {
                delay: 0.3 * custom,
                duration: 0.3 * custom
            }


        })

    }
    const variants4 = {
        hidden: {
            marginTop: '20%',
            opacity: 0
        },
        visible: (custom: number) => ({
            marginTop: '5%',
            opacity: 1,
            transition: {
                delay: 0.3 * custom,
                duration: 0.3 * custom
            }


        })

    }


    return (
        <motion.div style={{ marginTop }} className={s.barPages}>


            <div className={s.box1}>
                <motion.h2 variants={variants} animate={goAnim} transition={{ duration: 0.7 }} >
                    Напитки
                </motion.h2>

                <div className={s.foto1}>
                    <img src={pic} alt="" />
                    <div className={s.fotoText}>
                        <p>Предлагаем широчайший ассортимент </p>
                        <p>крафтовых настоек.</p>
                        <p>Неповторимые коктейли порадуют </p>
                        <p>самый взыскательный вкус</p>
                        <button
                            onClick={() => { window.open('https://t.me/spi4ki_bot') }}
                            className={s.button}>
                            Забронировать
                        </button>
                    </div>

                </div>

                <motion.div className={s.text}>
                    <motion.img variants={variants2} animate={goAnim} transition={{ duration: 1, delay: 0.5 }} src={pic2} alt="" />
                </motion.div>
            </div>
            <motion.div
                initial='hidden'
                whileInView='visible'
                className={s.box2}>
                <motion.h2 initial='hidden'
                    whileInView='visible' custom={1} variants={variants3} >
                    Закуски
                </motion.h2>

                <motion.div custom={4} initial={{ marginTop: '20%', opacity: 0 }}
                    transition={{ duration: 2 }}
                    variants={variants4}

                    className={s.text}>
                    <img src={snack2} alt="" />
                </motion.div>

                <motion.div custom={2} transition={{ duration: 6 }} variants={variants4} ref={ref} className={s.foto2}>
                    <img src={pic3} alt="" />
                    <div className={s.fotoText2}>
                        <p>Попробуйте наши вкусные блюда и закуски </p>
                        <p>в формате "Street Food".</p>
                        <p>Уверены, что наши фирменные хот-доги и бургеры,</p>
                        <p> а также всеми любимые кесадильи придутся вам по вкусу!</p>
                        <button
                            onClick={() => { window.open('https://t.me/spi4ki_bot') }}
                            className={s.button}>
                            Забронировать
                        </button>
                    </div>
                </motion.div>


            </motion.div>

            <div className={s.box3} ref={ref} >
                <motion.h2
                    initial={{ marginTop: '0%', opacity: 0.3 }}
                    whileInView={{ marginTop: '4%', opacity: 1 }}
                    viewport={{ amount: 0.5 }}
                    transition={{ duration: 1 }}
                >
                    Атмосфера
                </motion.h2>
                <motion.img animate={!isInView ? { opacity: 0, marginLeft: '-20%' } :
                    { opacity: 1, marginLeft: '10%' }}
                    transition={{ duration: 1 }}
                    className={s.pic1} src={athmos1} alt="" />
                <motion.img animate={!isInView ? { opacity: 0, marginLeft: '200%' } :
                    { opacity: 1, marginLeft: '40%' }}
                    transition={{ duration: 2 }}
                    className={s.pic2} src={athmos2} alt="" />

                <div className={s.text3}>
                    <p>Наш бар  - идеальное место, чтобы</p>
                    <p>насладиться незабываемыми моментами </p>
                    <p>в уютной и яркой атмосфере.</p>
                    <button
                        onClick={() => { window.open('https://t.me/spi4ki_bot') }}
                        className={s.button}>
                        Забронировать
                    </button>
                </div>


            </div>

        </motion.div>
    )
}

export default BarPages