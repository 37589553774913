import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IFood, IMenu } from './interfaces'

type TMenu = {
    menu: IMenu
    menuSeason: IFood[]
    season: boolean
    pw: string
    barName: string
}
type TMenuR = {
    menu: IMenu
    seasonMenu: IFood[]
    season: boolean
    resolve: boolean
}
type TReq = {
    data: string
}


const baseURL = 'https://spichki.by/ultralab/spichki'//'http://localhost:7003/ultralab/spichki'//


export const spichkiApi = createApi({
    reducerPath: 'menuAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    tagTypes: ['Menu'],
    endpoints: (build) => ({
        changeMenu: build.mutation<TReq, TMenu>({
            query: ({ menu, menuSeason, season, pw, barName }) => ({
                url: '/change',
                method: 'POST',
                body: {
                    menu,
                    menuSeason,
                    season,
                    pw,
                    barName
                },

            }),
           
        }),
        login: build.mutation<boolean, string>({
            query: (password: string) => ({
                url: '/login',
                method: 'POST',
                body: {
                    password
                },

            }),
           
        }),
        
        /*   changeCatalog: build.mutation<ICatalog[], dataTape2>({
               query: ({ number, catalogName, underCatalog, token }) => ({
                   url: '/create',
                   method: 'POST',
                   body: { number, catalogName, underCatalog },
                   headers: { Authorization: `${token}` }
               }),
               invalidatesTags: ['Catalog']
           }),*/

        getMenu: build.query<TMenuR, string>({
            query: (path) => ({ url: `/?bar=${path}` }),
            providesTags: (result) => ['Menu']

        }),
        /*
                createDevice: build.mutation<IDevice, dataTape3>({
                    query: ({ name, catalog, underCatalog, subCatalog, token }) => ({
                        url: '/createDevice',
                        method: 'POST',
                        body: { name, catalog, underCatalog, subCatalog },
                        headers: { Authorization: `${token}` }
                    }),
                    invalidatesTags: ['Catalog']
                }),
                deleteDevice: build.mutation<IDevice[], dataTape4>({
                    query: ({ name, token }) => ({
                        url: '/deleteDevice',
                        method: 'DELETE',
                        body: { name },
                        headers: { Authorization: `${token}` }
                    }),
                    invalidatesTags: ['Catalog']
                }),*/


    })
})
export const { useGetMenuQuery, useChangeMenuMutation, useLoginMutation } = spichkiApi;