import React, { useEffect, useState } from 'react'
import s from './stock.module.css'
import { IBar } from '../../app/interfaces'
import { motion } from 'framer-motion'
import pic1 from '../../img/stock1.jpg'
import NavBar2 from './NavBar2'
import NavBarM from '../mobile/NavBarM'

type Props = {
  bar: IBar
  anim4: string
}


const Stock = ({ bar, anim4 }: Props) => {

  const [actDay, setActiv] = useState(0)

  const dayWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресение']
  const dWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
  const days = ['-20% на все меню', '-20% на все меню', '1 + 1 на настойки', '1 + 1 на настойки',
    '4 + 1 на настойки', '4 + 1 на настойки', '5 + 5 на настойки']
  const daysKoms = ['-20% на все меню', '-20% на все меню', '1 + 1 на настойки', '1+1 на коктейли, 4+1 на настойки',
    '4 + 1 на настойки', '4 + 1 на настойки', '5 + 5 на настойки']
  const [day, setDays] = useState(dayWeek)

  useEffect(() => {
    const time = new Date()

    if (time.getDay() === 0) setActiv(6)
    else setActiv(time.getDay() - 1)
    if (anim4 !== 'navig') setDays(dWeek)
    else setDays(dayWeek)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const week = day.map((el, i) => {
    return (
      <div key={el} style={{ padding: ' 0 5% 0 5% ' }}>
        <div style={(i === actDay) ? { backgroundColor: '#fff' } : undefined} className={s.wrapper}>
          <p style={{ paddingLeft: '5%', color: (i === actDay) ? 'red' : '#fff' }}>{el}</p>
          <p style={{ paddingRight: '5%', color: (i === actDay) ? 'red' : '#fff' }}>
            {(bar.img === 1) ? daysKoms[i] : days[i]}
          </p>
        </div>
        <div className={s.line}></div>
      </div>

    )
  })

  return (
    <>

      <div className={s.stock}>
        {(anim4 === 'navig') &&
          < div >
            <NavBar2 desactive='Sto' tel={bar.tel} pathBar={bar.pathBar} nameBar={bar.name} goAnim={1} />
          </div>}
        {(anim4 === 'mobile') && <NavBarM bar={bar} />}
        <motion.h1
          whileInView={(anim4 === 'navig') ? { marginTop: ['10%', '5%'] } : undefined}
          transition={{
            duration: (anim4 === 'navig') ? 1 : 0,
          }}
        >АКЦИИ</motion.h1>
        <div className={s.conteiner}>
          <div className={s.photo}>
            <div className={s.boxPh}>
              <img src={pic1} alt="" />

            </div>

          </div>
          <div className={s.text}>
            {week}
          </div>
        </div>
      </div >
    </>
  )
}

export default Stock