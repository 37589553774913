
import { createSlice } from '@reduxjs/toolkit'

const spichkiSlice = createSlice({
  name: 'spichki',
  initialState: {
    size: {

      windowWidth: 0,
      windowHeight: 0,

    },

    start: false,
    stop: [false, null],
    season: false,
    bars: {
      okt19: {
        name: 'на Октябрьской, 19Б',
        schedule: [
          'пн,вт с 17.00 до 00.00',
          'ср,чт с 17.00 до 02.00',
          'пт,сб с 17.00 до 04.00',
          'вс с 17.00 до 02.00'
        ],
        adress: 'ул.Октябрьская, 19Б',
        tel: '+375 29 191 01 97',
        map: 'https://yandex.ru/map-widget/v1/?um=constructor%3Af6ce86208a6a60159e08822f0bbcbbb29b7ea1f99777e31f12e5ebcbd1c90406&amp;source=constructor',
        inst: 'https://www.instagram.com/spichki_bar_ok19b/',
        pathBar: 'okt19',
        img: 0
      },
      koms5: {
        name: 'на Комсомольской, 5A',
        schedule: [
          'пн,вт с 17.00 до 00.00',
          'ср,чт с 17.00 до 02.00',
          'пт,сб с 17.00 до 04.00',
          'вс с 17.00 до 02.00'
        ],
        adress: 'ул.Комсомольская, 5A',
        tel: '+375 29 101 01 97',
        map: 'https://yandex.ru/map-widget/v1/?um=constructor%3A8f472b1fcb75e02c0a7e648133b989b3fbc7179179fe7e8df0deadbc1d509b28&amp;source=constructo',
        inst: 'https://www.instagram.com/spichki_bar/',
        pathBar: 'koms5',
        img: 1
      },
      luch: {
        name: 'на Независимости, 95/5',
        schedule: [
          'пн,вт с 17.00 до 00.00',
          'ср,чт с 17.00 до 02.00',
          'пт,сб с 17.00 до 04.00',
          'вс с 17.00 до 02.00'
        ],
        adress: 'ул.Независимости, 95/5',
        tel: '+375 29 161 10 19',
        map: 'https://yandex.ru/map-widget/v1/?um=constructor%3Afab33354440fe3e38b2c5a03c495d621f431a0bf495890ba26bb3592642d9529&amp;source=constructor',
        inst: 'https://www.instagram.com/spichki_luch/',
        pathBar: 'luch',
        img: 2
      }
    },
resolve: false

  },
  reducers: {
    getSize(state, action) {

      state.size = { ...action.payload }

    },
    setStart(state, action) {


      state.start = action.payload

    },
    setStop(state, action) {


      state.stop = [...action.payload]

    },
    showSeason(state, action) {

      state.season = action.payload

    },
    changeResolve(state, action) {

      state.resolve = action.payload

    },

  },
})

export const { getSize, setStart, setStop, showSeason, changeResolve } = spichkiSlice.actions
export default spichkiSlice.reducer