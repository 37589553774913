import React, { useEffect, useRef } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import StartPage from './components/StartPage';
import { useDispatch } from 'react-redux';
import { getSize } from './app/slice';
import Menu from './components/bars/Menu';
import BarM from './components/mobile/BarM';
import { useAppSelector } from './app/Hooks';
import { selectorBar } from './app/selectors';
import Contacts from './components/bars/Contacts';
import Spichki from './components/bars/Spichki';
import Stock from './components/bars/Stock';
import ContactsM from './components/mobile/ContactsM';
import MenuEdit from './components/admin/MenuEdit';
import Enter from './components/admin/Enter';

const App: React.FC = () => {

  const dispatch = useDispatch()
  const bars = useAppSelector(selectorBar)
  const resolve = useAppSelector(state => state.spichki.resolve)
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);

  useEffect(() => {

    dispatch(getSize({ windowWidth: +windowWidth.current, windowHeight: +windowHeight.current }))
  }, [dispatch])


  return (
    <div  className="App">
      {+windowWidth.current > 860 &&
        <Routes>
          <Route path='' element={<StartPage />}></Route>
          <Route path='/okt19' element={<Spichki bar={bars.okt19} />}></Route>
          <Route path='/koms5' element={<Spichki bar={bars.koms5} />}></Route>
          <Route path='/luch' element={<Spichki bar={bars.luch} />}></Route>
          <Route path='/okt19/menu' element={<Menu bar={bars.okt19} anim4='navig' />}></Route>
          <Route path='/koms5/menu' element={<Menu bar={bars.koms5} anim4='navig'/>}></Route>
          <Route path='/luch/menu' element={<Menu bar={bars.luch} anim4='navig' />}></Route>
          <Route path='/okt19/contacts' element={<Contacts bar={bars.okt19} anim4='navig' />}></Route>
          <Route path='/koms5/contacts' element={<Contacts bar={bars.koms5} anim4='navig' />}></Route>
          <Route path='/luch/contacts' element={<Contacts bar={bars.luch} anim4='navig' />}></Route>
          <Route path='/okt19/stock' element={<Stock bar={bars.okt19} anim4='navig' />}></Route>
          <Route path='/koms5/stock' element={<Stock bar={bars.koms5} anim4='navig' />}></Route>
          <Route path='/luch/stock' element={<Stock bar={bars.luch} anim4='navig' />}></Route>
          <Route path='/dashboard' element={resolve ?<MenuEdit  anim4='navig'/>
          : <Enter />}></Route>
        </Routes>}
      {
        +windowWidth.current <= 860 && <Routes>
          <Route path='' element={<StartPage />}></Route>
          <Route path='/okt19' element={<BarM bar={bars.okt19} />}></Route>
          <Route path='/koms5' element={<BarM bar={bars.koms5} />}></Route>
          <Route path='/luch' element={<BarM bar={bars.luch} />}></Route>
          <Route path='/okt19/menu' element={<Menu bar={bars.okt19} anim4='mobile'/>}></Route>
          <Route path='/koms5/menu' element={<Menu bar={bars.koms5} anim4='mobile'/>}></Route>
          <Route path='/luch/menu' element={<Menu bar={bars.luch} anim4='mobile'/>}></Route>
          <Route path='/okt19/contacts' element={<ContactsM bar={bars.okt19} anim4='mobile' />}></Route>
          <Route path='/koms5/contacts' element={<ContactsM bar={bars.koms5} anim4='mobile' />}></Route>
          <Route path='/luch/contacts' element={<ContactsM bar={bars.luch} anim4='mobile' />}></Route>
          <Route path='/okt19/stock' element={<Stock bar={bars.okt19} anim4='mobile' />}></Route>
          <Route path='/koms5/stock' element={<Stock bar={bars.koms5} anim4='mobile' />}></Route>
          <Route path='/luch/stock' element={<Stock bar={bars.luch} anim4='mobile' />}></Route>
        </Routes>
      }

    </div>
  );
}

export default App;
