import React, { useEffect, useState } from 'react'
import s from './spichki.module.css'
import { useTransform, useScroll, motion, AnimatePresence } from 'framer-motion'
import NavBar2 from './NavBar2'

import { IBar } from '../../app/interfaces'
import Contacts from './Contacts'
import Stock from './Stock'
import img0 from '../../img/okt_title.jpg'
import img1 from '../../img/koms_title.jpg'
import img2 from '../../img/luch_title.jpg'
import BarPages from './BarPages'


type Props = {
  bar: IBar
}

const Spichki = ({ bar }: Props) => {

  const [start, setStart] = useState(false)
  const [flag, setFlag] = useState(true)
  const [navAn, setNavAn] = useState(0)
  const [barAn, setBarAn] = useState('finish')
  const [backgr, setBacgr] = useState(false)
  const images = [img0, img1, img2]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const { scrollY } = useScroll()

  const height = useTransform(scrollY, [0, 50], ['100vh', '0vh'])
  const width = useTransform(scrollY, [0, 50], ['100%', '0%'])
  const marginTop = useTransform(scrollY, [0, 200], ['0vh', '10vh'])


  useTransform(scrollY, value => {


    if (value > 50 && flag) {
      setFlag(false)
      setBacgr(true)
      setStart(true)
      setNavAn(1)

    }
    if (value < 50 && !flag) {
      setFlag(true)
      setBacgr(false)
      setStart(false)
      setNavAn(0)
    }
    if (value > 700 && barAn === 'finish') {
      setBarAn('start')
    }
    if (value < 700 && barAn === 'start') {
      setBarAn('finish')
    }

  })

  return (

    <div className={s.spichki}>



      <NavBar2 desactive={'Bar'} pathBar={bar.pathBar} nameBar={bar.name} goAnim={navAn} tel={bar.tel} />


      <AnimatePresence initial={true}  >
        {!start &&
          <motion.h1

            initial={{
              opacity: 0,
              top: -600
            }}
            animate={{ top: 0, fontSize: '160px', opacity: 1 }}
            exit={{
              opacity: 1,
              fontSize: '0px',
              top: -600
            }}
            transition={{ duration: 0.5 }} className={s.title}>Бар <motion.p>{bar.name} </motion.p>
          </motion.h1>
        }

      </AnimatePresence>

      <motion.div
        initial={{ backgroundSize: '0%' }}
        animate={backgr ? { backgroundSize: '20%' } : { backgroundSize: '0%' }}
        transition={{ duration: 1 }} className={s.startPic}
      >
        <motion.img style={{ width, height }}
          transition={{ duration: 1 }} src={images[bar.img]} alt="" />
      </motion.div>

      <BarPages marginTop={marginTop} goAnim={barAn} />
      <div className={s.stock}>
        <Stock anim4={s.wrapper4} bar={bar} />
      </div>


      <div className={s.contacts}>
        <Contacts anim4={s.wrapper4} bar={bar} />
      </div>


    </div>


  )
}
export default Spichki