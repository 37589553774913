import React, { useState } from 'react'
import s from './menuEdit.module.css'
import { IDrink } from '../../app/interfaces'
import { FaCircleCheck, FaCirclePlus, FaCircleXmark, FaPen, FaTrash } from 'react-icons/fa6'
type Props = {
    menu: IDrink[]
    index: number
    changeMenu: (newDrink: IDrink[]) => void
}

const Drink = ({ menu, index, changeMenu }: Props) => {

    const [edit, setEdit] = useState<number | null>(null)
    const [inputDrink, setInputDrink] = useState({
        cost: '', discr: '', name: ''
    })




    const addDrink = (i: number) => {
        let newList = [...menu[index].list]
        newList.splice(i + 1, 0, '')
        let newDiscr = [...menu[index].discr]
        newDiscr.splice(i + 1, 0, '')
        let newCost = [...menu[index].cost]
        newCost.splice(i + 1, 0, '')
        let a = [...menu]

        a.splice(index, 1,
            {
                cost: newCost, discr: newDiscr, list: newList,
                nameD: menu[index].nameD, ml: menu[index].ml
            })

        changeMenu(a)
    }

    const delDrink = (i: number) => {
        let newList = [...menu[index].list]
        newList.splice(i, 1)
        let newDiscr = [...menu[index].discr]
        newDiscr.splice(i, 1)
        let newCost = [...menu[index].cost]
        newCost.splice(i, 1)
        let a = [...menu]

        a.splice(index, 1,
            {
                cost: newCost, discr: newDiscr, list: newList,
                nameD: menu[index].nameD, ml: menu[index].ml
            })

        changeMenu(a)
    }
    const changeDrink = (i: number) => {
        let newList = [...menu[index].list]
        newList.splice(i, 1, inputDrink.name)
        let newDiscr = [...menu[index].discr]
        newDiscr.splice(i, 1, inputDrink.discr)
        let newCost = [...menu[index].cost]
        newCost.splice(i, 1, inputDrink.cost)
        let a = [...menu]

        a.splice(index, 1,
            {
                cost: newCost, discr: newDiscr, list: newList,
                nameD: menu[index].nameD, ml: menu[index].ml
            })

        changeMenu(a)
    }
    const editDrinks = (i: number) => {
        return (
            <>
                <div className={s.wrapperInput} style={{ textAlign: "start", maxWidth: '70%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <input type="text" value={inputDrink.name}
                            onChange={(e) => {
                                setInputDrink({
                                    cost: inputDrink.cost, discr: inputDrink.discr,
                                    name: e.currentTarget.value
                                })
                            }}

                        />
                        <textarea value={inputDrink.discr}
                            onChange={(e) => {
                                setInputDrink({
                                    cost: inputDrink.cost, discr: e.currentTarget.value,
                                    name: inputDrink.name
                                })
                            }}
                        />
                    </div>



                    <input type="text" value={inputDrink.cost}
                        onChange={(e) => {
                            setInputDrink({
                                cost: e.currentTarget.value, discr: inputDrink.discr,
                                name: inputDrink.name
                            })
                        }}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
                    <FaCircleCheck cursor={'pointer'} size={30} onClick={() => {
                        changeDrink(i);
                        setEdit(null)
                    }} />
                    <FaCircleXmark cursor={'pointer'} size={30} onClick={() => { setEdit(null) }} />
                </div>

            </>
        )
    }
    const itemDrinks = menu[index].list.map((el, i) => {
        return (
            <div className={s.drinkItem} key={el + i}>
                {(edit !== i) ? <>
                    <div style={{ textAlign: "start", width: '40%' }}>
                        <p>{el}</p>
                        <p style={{ fontSize: "18px", fontWeight: 300 }}>
                            {menu[index].discr[i]}</p>
                    </div>

                    <p style={{ textAlign: "end" }}>{menu[index].cost[i]}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '140px' }}>
                        <FaPen size={30} cursor={'pointer'} onClick={() => {
                            setEdit(i);
                            setInputDrink({
                                cost: menu[index].cost[i],
                                discr: menu[index].discr[i],
                                name: el
                            })
                        }} />
                        <FaTrash cursor={'pointer'} size={30} onClick={() => { delDrink(i) }} />
                        <FaCirclePlus cursor={'pointer'} size={30} onClick={() => { addDrink(i) }} />
                    </div>
                </>
                    : editDrinks(i)}


            </div>
        )
    })


    return (
        <>
            {itemDrinks}
        </>
    )
}

export default Drink