import React, { useState } from 'react'
import s from './login.module.css'
import { useAppDispatch } from '../../app/Hooks'
import { useLoginMutation } from '../../app/slice_API'
import { changeResolve } from '../../app/slice'
type Props = {
    
   
}

const Enter = (props: Props) => {

    const [login] = useLoginMutation()
    const dispatch = useAppDispatch()
    const [password, setPassword] = useState('')

    return (
        <div className={s.login} style={{marginLeft: '30%'}}>
            <h3>Введите пароль</h3>
            <input type="password" placeholder='Введите пароль' value={password}
                onChange={(e) => { setPassword(e.currentTarget.value) }} />
            <div className={s.buttons}>
                <button onClick={() => {
                    login(password).unwrap()
                    .then(res => 
                        {
                      
                            dispatch(changeResolve(res))}
                    
                                    )
                    
                }}>Подтвердить</button>
                
            </div>
        </div>
    )
}

export default Enter