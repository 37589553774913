import React, { useEffect, useState } from 'react'
import s from './secondPage.module.css'
import NavBar from './NavBar'
import { selectorSize, selectorStop } from '../app/selectors'
import { useAppSelector } from '../app/Hooks'


type Props = {}

const SecondPage = (props: Props) => {
  const size = useAppSelector(selectorSize)
  const stop = useAppSelector(selectorStop)
  const [clName, setClName] = useState(s.secondPage)
  const [startNav, setStartNav] =useState(false)


  useEffect(() => {
    if (stop[0]) {
      setClName(s.secondPage3)
    }
     
  }, [stop])
 
  useEffect(() => {
    setTimeout(() => {
     setStartNav(true)
    }, 2000)
  }, [])
 
  
  
  return (
    <div   className={+size.windowWidth > 860 ? clName : undefined}>
      <div className={s.test}>
        {(startNav && +size.windowWidth > 860 )  && <NavBar />}
      </div>
      
      <div className={s.conteiner}>
      </div>
      {+size.windowWidth <= 860 && <NavBar />}
    </div>
  )
}

export default SecondPage