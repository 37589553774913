import React from 'react'
import s from './barPagesM.module.css'
import pic2 from '../../img/athmos1.jpg'
import pic3 from '../../img/food2.jpg'
import athmos1 from '../../img/athm1.jpg'




type Props = {
   
}
const BarPagesM = (props: Props) => {

    return (
        <div  className={s.barPages} >
                <div className={s.box1}>
                    <h2  >
                        Напитки
                    </h2>

                    <div className={s.foto1} >
                        <img  src={pic2} alt="" />
                        <div  className={s.fotoText}>
                            <p>Предлагаем широчайший ассортимент </p>
                            <p>крафтовых настоек.</p>
                            <p>Неповторимые коктейли порадуют </p>
                            <p>самый взыскательный вкус</p>
        
                        </div>

                    </div>

              
                </div>
                <div
                   onClick={()=> {console.log('1');
                }}
                    className={s.box1}>
                    <h2>
                        Закуски
                    </h2>


                    <div className={s.foto1}>
                        <img src={pic3} alt="" />
                        <div className={s.fotoText}>
                            <p>Попробуйте наши вкусные блюда  </p>
                            <p>и закуски в формате "Street Food".</p>
                            <p>Уверены, что наши фирменные хот-доги </p>
                            <p> и бургеры, а также всеми любимые </p>
                            <p>кесадильи придутся вам по вкусу!</p>
                        
                        </div>
                    </div>


                </div>

                <div className={s.box1} >
                    <h2>
                        Атмосфера
                    </h2>
                    <div className={s.foto1}>

                    <img className={s.pic1} src={athmos1} alt="" />
                    

                    <div className={s.fotoText}>
                        <p>Наш бар  - идеальное место, чтобы</p>
                        <p>насладиться незабываемыми моментами </p>
                        <p>в уютной и яркой атмосфере.</p>
                      
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BarPagesM