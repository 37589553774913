import React from 'react'
import s from './barM.module.css'
import { IBar } from '../../app/interfaces'
import img0 from '../../img/okt_title.jpg'
import img1 from '../../img/koms_title.jpg'
import img2 from '../../img/luch_title.jpg'
import BarPagesM from './BarPagesM';
import Stock from '../bars/Stock';
import NavBarM from './NavBarM'
import ContactsM from './ContactsM'


type Props = {
    bar: IBar
}

export default function BarM({ bar }: Props) {
    const img = [img0, img1, img2]
    return (
        <div className={s.barM}>

            <NavBarM bar={bar} />
            <div className={s.title}>
                <h1>Бар</h1>
                <h1>{bar.name}</h1>
            </div>

            <div className={s.conteiner} >

                <img src={img[bar.img]} alt="" />
            </div>
            <div className={s.barPagesM}>
                <BarPagesM />
            </div>
            <div className={s.stock}>
                <Stock bar={bar} anim4='' />
            </div>
            
            <div className={s.stock}>
                <ContactsM bar={bar} anim4=''/>
            </div>
            
        </div>
    )
}