import React from 'react'
import s from './contacts.module.css'
import { IBar } from '../../app/interfaces'
import { RiInstagramLine, RiTelegramLine } from 'react-icons/ri'
import NavBar2 from './NavBar2'
import logo from '../../img/spichki_WE.png'
import { useNavigate } from 'react-router-dom'


type Props = {
  bar: IBar
  anim4: string

}

const Contacts = ({ bar, anim4 }: Props) => {

  const navigate = useNavigate();
  const { pathBar } = bar;

  const BurgerM = () => {
    return (
      <div className={s.burgerM}>
        <div className={s.item}>
          <p
            onClick={() => navigate(`/${pathBar}`)}
            className={s.menuItem}> На главную
          </p>
          <p
            onClick={() => navigate(`/${pathBar}/menu`)}
            className={s.menuItem}>Меню
          </p>

          <p onClick={() => { window.open('https://t.me/spi4ki_bot') }}
            className={s.menuItem}>Бронирование</p>

          <p
            onClick={() => { navigate(`/${pathBar}/stock`) }} className={s.menuItem}>Акции</p>
          <p
            onClick={() => { navigate(`/${pathBar}/contacts`) }} className={s.menuItem}>Контакты</p>

        </div>
      </div>
    )
  }


  return (
    <>
    

      <div className={s.contacts} >
          {(anim4 === 'navig') &&
        <div>
          <NavBar2 desactive='Kont' tel={bar.tel} pathBar={bar.pathBar} nameBar={bar.name} goAnim={1} />
        </div>
      }
        <div className={s.header}>

          <div className={s.map}>
            <iframe title='map' style={{ width: "100%", height: "100%" }} src={bar.map}  ></iframe>
          </div>
          <div className={s.text}>

            <h3 style={{ marginTop: "20px" }}>График работы</h3>
            {
              bar.schedule.map((el, i) => {
                return (
                  <p key={el}>{el}</p>
                )
              })
            }
          </div>
        </div>

        <div className={s.footer}>

          
            <img src={logo} alt="" />
         
          
            <BurgerM />
          
          <div className={s.cont}>
            <h3 >Адрес</h3>

            <p>г.Минск</p>
            <p>{bar.adress}</p>
            <h3 style={{ marginTop: "20px" }}>Телефон</h3>
            <p style={{ marginBottom: '10px' }}>{bar.tel}</p>

            <RiInstagramLine size={40} onClick={() => { window.open(bar.inst) }} className={s.inst} />
            <RiTelegramLine size={40}  onClick={() => { window.open('https://t.me/spi4ki_bot') }} className={s.inst} />

          </div>
        </div>
      </div>
    </>

  )
}
export default Contacts