import React from 'react'
import s from './navBar2.module.css'
import { useNavigate } from 'react-router-dom'
import pic from '../../img/spichki_WWord.png'
import {  RiPhoneFill } from 'react-icons/ri'



type Props = {

  goAnim: number
  tel: string
  pathBar: string
  nameBar: string
  desactive?: string
}

const NavBar2 = ({ goAnim, tel, pathBar, nameBar, desactive }: Props) => {

  const navigat = useNavigate()




  const classNavbar2 = [s.navBar2, s.navBar2Up, s.navBar2Down]
  const back = [s.back, s.backUp, s.backDown]


  return (
    <div className={classNavbar2[goAnim]}>
      <div className={s.conteiner}>



        <div className={s.navig}>
          <div>
            <img onClick={() => { navigat('/') }} src={pic} alt="" />
          </div>

          <div className={s.menu}>
            {(+goAnim === 1) && <div style={(desactive === 'Bar') ? { cursor: 'default' } : undefined} className={s.title}>
              <p onClick={() => { navigat(`/${pathBar}`) }}>Бар {nameBar}</p>
            </div>}
            <div style={(desactive === 'Меню') ? { cursor: 'default' } : undefined}
              onClick={() => navigat(`/${pathBar}/menu`)} className={s.menuItem}>Меню</div>

            <div onClick={() => { window.open('https://t.me/spi4ki_bot') }} className={s.menuItem}>Бронирование</div>

            <div style={(desactive === 'Sto') ? { cursor: 'default' } : undefined} onClick={() => {navigat(`/${pathBar}/stock`)}} className={s.menuItem}>Акции</div>
            <div style={(desactive === 'Kont') ? { cursor: 'default' } : undefined} onClick={() => { navigat(`/${pathBar}/contacts`) }} className={s.menuItem}>Контакты</div>

            <div style={{ cursor: "default" , display: 'flex'}} className={s.menuItem}>
              <div>
                <RiPhoneFill style={{ marginTop: '2px' }} />
              </div>
              <div style={{ marginLeft: '5px' }}>{tel}</div>
            </div>
          </div>

        </div>
        <div className={back[goAnim]}>

        </div>
      </div>
    </div>
  )
}


export default NavBar2