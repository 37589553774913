import { setupListeners } from '@reduxjs/toolkit/query';
import { configureStore } from '@reduxjs/toolkit'
import spichkiReducer from './slice'
import { spichkiApi } from './slice_API';


export const store = configureStore({
  reducer: {
    [spichkiApi.reducerPath]: spichkiApi.reducer,
    spichki: spichkiReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      spichkiApi.middleware,
      )
  ,

})
setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch